<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Bills List</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6" v-if="pagination">
          <el-select
            class="select-default"
            v-model="pagination.per_page"
            placeholder="Per page"
            @change="changePerPage()"
          >
            <el-option
              class="select-default"
              v-for="item in perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            ></fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%;"
            height="600"
          >

            <el-table-column type="expand" fixed>
              <template slot-scope="props">
                <!-- <p v-for="(item, index) in props.row.items" :key="index">{{ item }}</p> -->
                <el-table
                  class="table-striped"
                  :data="props.row.items"
                  border
                >
                  <el-table-column type="index"></el-table-column>
                  <el-table-column width="100" label="Prod ID" prop="product.product_id" align="center"></el-table-column>
                  <el-table-column width="250" label="Name" prop="product.name" align="left"></el-table-column>
                  <el-table-column width="100" label="Type" prop="product.product_type.name" align="center"></el-table-column>
                  <el-table-column width="100" label="Price" align="center">
                    <!-- <div slot-scope="{row}" class="td-number">{{ `Rs. ${row.product.sale_price - Math.ceil(row.product.sale_price * (row.product.gst_type.percentage/100))}` }}</div> -->
                    <div slot-scope="{row}" class="td-number">{{ `Rs. ${row.unit_price}` }}</div>
                  </el-table-column>

                  <el-table-column width="100" label="GST %" align="center">
                    <div slot-scope="{row}" class="td-number">{{ `${row.gst} %` }}</div>
                  </el-table-column>

                  <el-table-column width="100" label="GST" align="center">
                    <div slot-scope="{row}" class="td-number">{{ `Rs. ${Math.ceil(row.unit_price * (row.product.gst_type.percentage/100))}` }}</div>
                  </el-table-column>

                  <el-table-column width="100" label="U.Price" align="center">
                    <div slot-scope="{row}" class="td-number">{{ `Rs. ${row.product_price}` }}</div>
                  </el-table-column>
                  
                  <el-table-column width="150" label="Quantity" prop="quantity" align="center"></el-table-column>
                  <el-table-column width="200" label="Total" align="center">
                    <template slot-scope="props">
                      {{ props.row.product_price * props.row.quantity  }}
                    </template>
                  </el-table-column>
                  <el-table-column width="200" label="Discount Type" align="center">
                    <template slot-scope="props">
                      {{ props.row.discount.value != 0 ? props.row.discount.type : props.row.discount.name  }}
                    </template>
                  </el-table-column>
                  <el-table-column width="150" label="Disc. Value" align="center">
                    <template slot-scope="props">
                      {{ (props.row.discount.type == 'reduction') ? `${props.row.discount.value}/-` :  (props.row.discount.type == 'percent') ? `${props.row.discount.value}%` : ''}}
                    </template>
                  </el-table-column>
                  <el-table-column width="150" label="Disc. Value" align="center">
                    <template slot-scope="props">
                      {{ (props.row.discount.type == 'reduction') ? (props.row.discount.value  * props.row.quantity) :  (props.row.discount.type == 'percent') ? Math.floor((props.row.product_price * props.row.quantity) * (props.row.discount.value/100)) : ''}}
                    </template>
                  </el-table-column>
                  <!-- <el-table-column width="150" label="NetAmount" prop="total_value" align="center"></el-table-column> -->
                  <el-table-column width="150" label="NetAmount" align="center">
                    <div slot-scope="{row}" class="td-number">{{ `Rs. ${Math.ceil(row.total_value)}` }}</div>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>

            <el-table-column
              v-for="(column, index) in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable || false"
              :align="column.align"
              :header-align="column.align"
              v-if="!column.hidden"
              :fixed="column.fixed || false"
              :formatter="column.formatter || null"
            ></el-table-column>


            <el-table-column :min-width="200" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <!-- <p-button type="info" size="sm" icon @click="handleLike(props.$index, props.row)">
                  <i class="fa fa-eye"></i>
                </p-button>-->
                <p-button
                  type="default"
                  size="sm"
                  icon
                  @click="handleEdit(props.$index, props.row)"
                > 
                  <i class="fa fa-edit"></i>
                </p-button>
               
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-trash"></i>
                </p-button>

                <p-button
                  type="success"
                  size="sm"
                  icon
                  @click="handlePayments(props.$index, props.row)"
                >
                  <i class="fa fa-credit-card"></i>
                </p-button>

                <p-button
                  type="info"
                  size="sm"
                  icon
                  @click="handleView(props.$index, props.row)"
                >
                  <i class="fa fa-file"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info"  v-if="pagination"> 
          <p
            class="category"
          >Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries</p>
        </div>
        <div class="col-sm-6"  v-if="pagination">
          <p-pagination
            class="pull-right"
            v-model="pagination.current_page"
            :per-page="Number.parseInt(pagination.per_page)"
            :total="pagination.total"
            @input="changePage()"
          ></p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import axios from 'axios';
import { Table, TableColumn, Select, Option } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
// import users from "../Tables/users.js";
import swal from 'sweetalert2'
import { mapGetters } from "vuex";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);

export default {
  components: {
    PPagination
  },
  mounted() {
    this.$store.dispatch("fetchBillings", { per_page: 10, page: 1 });
  },
  watch: {
      searchQuery: function (val) {
          this.$store.dispatch("fetchBillings", {
              searchQuery: this.searchQuery,
              per_page: this.pagination.per_page,
              page: this.pagination.current_page
          }); 
      }
  },
  computed: {
    ...mapGetters({
      tableData: "getBillings",
      pagination: "getPagination"
    }),
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      // if (!this.searchQuery) {
      //   if(this.locations)
      //   this.pagination.total = this.locations.length;
      //   else
      //   this.pagination.total = 0
      //   return this.pagedData;
      // }
      // let result = this.locations.filter(row => {
      //   let isIncluded = false;
      //   for (let key of this.propsToSearch) {
      //     let rowValue = row[key].toString();
      //     if (rowValue.includes && rowValue.includes(this.searchQuery)) {
      //       isIncluded = true;
      //     }
      //   }
      //   return isIncluded;
      // });
      // this.pagination.total = result.length;
      // return result.slice(this.from, this.to);
    }
  },
  data() {
    return {
      perPageOptions: ["10", "30", "50", "100", "250", "500"],
      searchQuery: "",
      propsToSearch: [
        "billing_id",
        "patient_id",
        "patient_name",
        "patient_phone",
        "location_id",
        "location_name",
        "employee_id",
        "employee_name",
        "prescription_id",
        "start_date",
        "no_of_days",
        "date",
        "total",
        "discount",
        "amount",
        "delivery_note",
        "patient.shipping_state"
      ],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 50,
          hidden: true,
          align: "center"
        },
        {
          prop: "pid",
          label: "ID",
          minWidth: 50,
          hidden: true,
          align: "center"
        },
        {
          prop: "lid",
          label: "ID",
          minWidth: 50,
          hidden: true,
          align: "center"
        },
        {
          prop: "billing_id",
          label: "Bill ID",
          minWidth: 150,
          fixed: true,
          sortable: true,
          align: "center",
          formatter: function(row, column, cellValue, index){
             return `${cellValue}${row.isEstimate ? ' - E' : ''}`; 
          }
        },
        {
          prop: "prescription.prescription_id",
          label: "Presc. ID",
          minWidth: 150,
          fixed: false,
          sortable: true,
          align: "center"
        },
        {
          prop: "patient.patient_id",
          label: "Pat ID",
          minWidth: 150,
          sortable: true,
          align: "left"
        },
        {
          prop: "patient.name",
          label: "Patient Name",
          minWidth: 250,
          align: "left",
          fixed: "left"
        },
        {
          prop: "patient.phone",
          label: "Patient Phone",
          minWidth: 250,
          align: "center"
        },
        {
          prop: "location_id",
          label: "Loc ID",
          minWidth: 150,
          sortable: true,
          align: "left"
        },
        {
          prop: "location_name",
          label: "Location Name",
          minWidth: 250,
          align: "left"
        },
        {
          prop: "employee_id",
          label: "EMP ID By",
          minWidth: 150,
          sortable: true,
          align: "left"
        },
        {
          prop: "employee_name",
          label: "Created By",
          minWidth: 200,
          sortable: true,
          align: "left"
        },
        {
          prop: "start_date",
          label: "Start Date",
          minWidth: 150,
          sortable: true,
          align: "center"
        },
        {
          prop: "no_of_days",
          label: "Days",
          minWidth: 150,
          sortable: true,
          align: "center"
        },
        {
          prop: "date",
          label: "Date",
          minWidth: 150,
          sortable: true,
          align: "center"
        },
        {
          prop: "GST_type",
          label: "GST Type",
          minWidth: 150,
          sortable: true,
          align: "center"
        },
        {
          prop: "gstin",
          label: "GSTIN",
          minWidth: 250,
          sortable: true,
          align: "center"
        },
        {
          prop: "patient.shipping_state",
          label: "Place of Suppliy",
          minWidth: 250,
          align: "left"
        },
        {
          prop: "GST_amount",
          label: "GST",
          minWidth: 150,
          sortable: true,
          align: "center"
        },
        {
          prop: "total",
          label: "Total",
          minWidth: 150,
          sortable: true,
          align: "center"
        },
        {
          prop: "discount",
          label: "Discount",
          minWidth: 150,
          sortable: true,
          align: "center"
        },
        {
          prop: "shipping_charges",
          label: "Shipping",
          minWidth: 150,
          sortable: true,
          align: "right",
          formatter: function(row, column, cellValue, index){
            return `Rs. ${cellValue}`; 
          }
        },
        {
          prop: "consultation_charge",
          label: "Consultation",
          minWidth: 200,
          sortable: true,
          align: "right",
          formatter: function(row, column, cellValue, index){
            return `Rs. ${cellValue}`; 
          }
        },
        {
          prop: "amount",
          label: "Amount",
          minWidth: 150,
          sortable: true,
          align: "right",
          formatter: function(row, column, cellValue, index){
            return `Rs. ${cellValue}`; 
          }
        },
        {
          prop: "due_amount",
          label: "Due",
          minWidth: 150,
          sortable: true,
          align: "right",
          formatter: function(row, column, cellValue, index){
            return `Rs. ${cellValue}`; 
          }
        },
        {
          prop: "isEstimate",
          label: "Is Estimate?",
          minWidth: 200,
          sortable: true,
          align: "center",
          formatter: function(row, column, cellValue, index){
            return cellValue ? `Yes` : `No`; 
          }
        },
        {
          prop: "delivery_note",
          label: "Delivery Note",
          minWidth: 250,
          sortable: true,
          align: "left"
        },
      ]
      // tableData: this.locations
    };
  },
  methods: {
    changePerPage() {
      this.$store.dispatch("fetchBillings", {
        per_page: this.pagination.per_page,
        page: 1, searchQuery : this.searchQuery
      });
    },
    changePage() {
      this.$store.dispatch("fetchBillings", {
        per_page: this.pagination.per_page,
        page: this.pagination.current_page, searchQuery : this.searchQuery
      });
    },
    handleLike(index, row) {
      // alert(`Your want to like ${row.name}`);
      // this.$router.push(`/prescriptions/view/${row.id}`)
    },
    handleEdit(index, row) {
      this.$router.push(`/billings/edit/${row.id}`)
    },
    handlePayments(index, row) {
      this.$router.push(`/billings/payments/${row.id}`)
    },
    handleDelete(index, row) {
      // this.$store.dispatch("deleteBilling", row.id);
      swal({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonClass: "btn btn-danger btn-fill",
          cancelButtonClass: "btn btn-default btn-fill",
          confirmButtonText: "Delete it!",
          buttonsStyling: false
      })
      .then(result => { this.$store.dispatch("deleteBilling", row.id); })
      .catch(cancel => { console.log(cancel) });
    },
    handleView(index, row) {
      // this.$store.dispatch("deleteBilling", row.id);
      let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
      let headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
      };

      axios({
        url: `${process.env.VUE_APP_API_URL}/auth/invoice/${row.id}`,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice-${row.billing_id}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
      // window.open(`${process.env.VUE_APP_API_URL}/invoice/${row.id}`, "_blank", );
    }
  }
};
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}

.el-table,
.el-table tr,
.el-table thead th {
  background-color: white !important;
}
</style>
